// font family
$font-family: "Roboto", sans-serif;

// colors
$primary-color: #1D3946;
$secondary-color: #266381;
$dark-blue-color: #162F3B;
$border-color: #808289;
$text-color: #000;
$white-color: #fff;
$black-color: #000;
$blue-70: rgba(53, 131, 169, 0.7);
$error-color: #CF3C4F;
$stap-bg-color : #EBF3F7;
$gray-color :#666666;
$form-description-color: rgba(117, 117, 117, 0.46);
$active-step-bg-color: #3583A9;
$green-color: #34A853;
$light-blue-color: #0066FF;
$btn-primary-color: #3880FF;
$btn-second-color: #3583A9;