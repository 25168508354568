@import "../variables";
@import "../mixin";

.page-form-wrapper {
    background: $white-color;
    padding: 50px 0px 10px 0px;
    border-radius: 15px;
    max-width: 960px;
    margin: 0 auto;

    @include for-large-desktops {
        padding: 0px 0px 10px 0px;

    }

    @include for-landscape-phones {
        padding: 0px;
        margin-right: calc(var(--bs-gutter-x) / -2);
        margin-left: calc(var(--bs-gutter-x) / -2);
        border-radius: 0px;

        &.edit-profile-wrapper {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .form-control {
        border: 0;
        border-bottom: 1px solid #808289;
        border-radius: 0;
        background-color: $white-color !important;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: rgba(157, 159, 166, 0.37) !important;
    }

    // .form-group {
    //     @include for-landscape-phones {
    //         &.mb-40 {
    //             margin-bottom: 33px;
    //         }
    //     }
    // }
}

.forgot-password-wrapper {
    .forgot-password-link {
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;

        @include for-landscape-phones {
            font-size: 16px;
        }
    }
}

.logo-moto-text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.84px;
    margin-top: 10px;
}

.or-seprator-wrapper {
    .or-seprator {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background-color: $black-color;
            height: 1px;
            width: 100%;
            left: 0;
            right: 0;
            top: 12px;
        }
    }

    span {
        display: inline-block;
        padding: 0 30px;
        background-color: $white-color;
        position: relative;
    }
}

.signup-wrapper {
    p {
        color: $black-color;
        font-size: 18px;
        font-weight: 500;

        @include for-landscape-phones {
            font-size: 15px;
        }
    }

    span {
        margin-left: 6px;
    }

    a {
        text-decoration: none;
    }
}

.login-footer-wrapper {
    .list-group {
        display: flex;
        list-style: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;

        @include for-landscape-phones {
            padding-top: 0px;
        }
    }

    .list-group .list-group-item {
        border: 0px;
        padding: 0px 5px;

        @include for-landscape-phones {
            padding: 0px 3px;

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                padding-right: 0px;
            }
        }
    }

    .list-group-item a {
        color: $text-color;
        font-size: 14px;
        text-decoration: none;
        line-height: 20px;
        font-weight: 500;

        &:hover {
            color: $primary-color;
        }

        @include for-landscape-phones {
            font-size: 12px;
        }
    }

}

.page-form-content-wrapper {
    padding: 50px 50px 0px 50px;

    @include for-landscape-phones {
        padding: 15px 10px 0px;
    }
}

.brand-logo-img {
    @include for-landscape-phones {
        width: 80%;
    }
}

.google-signup-wrapper {
    .btn-primary-without-bg {
        padding: 5px 25px;
    }
}

.bs-stepper-line {
    flex: 1 0 32px;
    min-width: 1px;
    min-height: 1px;
    margin: auto;
    background-color: $black-color;
}

.stapper-form-step-wrapper {
    .step {
        padding: 0 15px;

        @include for-landscape-phones {
            padding: 0 5px;
        }

        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }

        .bs-stepper-count {
            background-color: $stap-bg-color;
            height: 32px;
            width: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            color: $black-color;

            @include for-landscape-phones {
                height: 24px;
                width: 24px;
                font-size: 12px;
            }
        }

        .bs-stepper-label {
            color: $gray-color;
            margin-left: 8px;

            @include for-landscape-phones {
                margin-left: 4px;
                font-size: 12px;
            }
        }

        &.active {
            .bs-stepper-count {
                background-color: $active-step-bg-color;
                color: $white-color;
            }

        }
    }
}

.form-title-content-wrapper {
    h1 {
        font-size: 32px;
        font-weight: 500;
    }

    @include for-landscape-phones {
        font-size: 26px;
    }

    p {
        color: $black-color;
    }

    p.color-black {
        color: $black-color;
        font-weight: 500;
    }

    @include for-landscape-phones {
        font-size: 14px;
    }
}

#accept-term-condition {
    margin-right: 7px;
}

.resend-email-btn {
    color: $green-color;
    border: 0px;
    background-color: transparent;
    font-weight: 600;
}

.forgot-password-steps {
    padding: 50px 0px 0px 0px;
    // @include for-landscape-phones {
    //     .stapper-form-step-wrapper {
    //         .step {
    //             .bs-stepper-count {
    //                 height: 20px;
    //                 width: 20px;
    //             }

    //             .bs-stepper-label {
    //                 font-size: 11px;
    //             }
    //         }

    //         .bs-stepper-line {
    //             flex: 1 0 15px;
    //         }

    //     }
    //     padding: 50px 10px 0px 10px;
    // }
    @include for-landscape-phones {
        padding: 30px 10px 0px 10px;
    }
}

.login-right-content-wrapper {
    .brand-logo-img {
        max-height: 115px;
    }

    .mobile-brand-logo {
        display: none;
    }

    @include for-landscape-phones {
        .brand-logo-img {
            display: none;
        }

        .mobile-brand-logo {
            display: unset;
            padding: 15px 0px;
            max-height: 80px;
            width: auto;
        }

        background-color: $primary-color;
        margin-bottom: 15px;
    }
}

.signup-form-wrapper , .forgot-password-steps{
    margin-top: 30px;

    .btn-primary-without-bg {
        color: $primary-color;
        border: 1px solid $primary-color;

        &:hover , &:focus {
            background-color: $primary-color;
            color: $white-color;
            border: 1px solid $primary-color;
        }
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.login-user-dashboard {
    @include for-landscape-phones {
        #content.auth-user-content {
            padding: 0px;
        }
    }
}