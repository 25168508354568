@import 'react-big-calendar/lib/sass/styles.scss';
@import "../variables";
@import "../mixin";

.calendar-content-wrapper {
    position: relative;

    .rbc-toolbar {

        .toolbar-nav-btn {
            height: 32px;
            width: 32px;
            padding: 0;
            border: 1px solid #D8DAE5;
            border-radius: 4px;

            @include for-landscape-phones {
                height: 28px;
                width: 28px;
            }

            svg {
                font-size: 14px;
            }
        }

        .month-dd,
        .year-dd {
            position: relative;
            box-shadow: none;
            margin: 0 17px;
            max-width: 120px;

            @include for-landscape-phones {
                max-width: 110px;
                margin: 0 3px;
            }

            .form-control {
                border: 0px;
                width: 120px;
                background-color: transparent;
                font-weight: 500;

                @include for-landscape-phones {
                    width: 110px;
                    background: transparent;
                    position: relative;
                    z-index: 1;
                    font-size: 15px;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .select-icon {
                position: absolute;
                top: 6px;
                right: 5px;
                z-index: -1;

                @include for-landscape-phones {
                    z-index: 0;
                }
            }
        }

        .year-dd {
            max-width: 90px;
            color: #8F95B2;

            @include for-landscape-phones {
                max-width: 75px;
            }

            .form-control {
                width: 77px;
                color: #8F95B2;
                font-weight: 500;

                @include for-landscape-phones {
                    width: 77px;
                }
            }
        }

        // .month-dd:before,
        // .year-dd:before {
        //     content: "\f078";
        //     position: absolute;
        //     font-family: "Font Awesome 5";
        // }

        .display-view-dd {
            width: 100px;
            position: relative;
            margin-left: 20px;
            color: #64748B;

            @include for-landscape-phones {
                display: none !important;
            }

            .form-control {
                background-color: transparent;
                max-width: 100px;
                border: 1px solid #D8DAE5;
                color: #64748B;
                font-weight: 500;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .select-icon {
                position: absolute;
                top: 7px;
                right: 7px;
                z-index: -1;
            }
        }
    }

    .rbc-month-header {
        color: #8F95B2;
        // background-color: black;
        border: none;
        border-width: 0px;
        text-transform: uppercase;
        font-weight: 500;

        position: fixed;
        top: 137px;
        width: 100%;
        z-index: 99;
        background: white;

        @include for-landscape-phones {
            position: relative;
            top: unset;
        }
        span {
            font-weight: 400;
        }
    }

    .rbc-month-view {
        border-top: 0px;
        border: none;
        border-bottom: 1px solid #ddd;
        position: relative;

    }

    .rbc-row-bg {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

    .rbc-header {
        border-left: 0px;
    }

    .rbc-day-slot .rbc-time-slot {
        border-top: 0px;
    }

    .rbc-events-container {
        margin-right: unset;

        div {
            font-size: 14px;
            border-radius: 0px;
            padding-top: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .rbc-time-header,
    .rbc-overflowing {
        margin-right: unset !important;
    }

    .rbc-time-content::-webkit-scrollbar {
        display: none;
    }

    .rbc-row-segment {
        padding: unset !important;

        .rbc-event-content {
            font-weight: 500;

            div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 10px;
            }
        }
    }

    .rbc-date-cell {
        text-align: center;
        padding: 5px 0px;

        @include for-landscape-phones {
            padding: 0px 0px;
        }

        .rbc-button-link {
            font-weight: 500;
            position: relative;
            z-index: 2;

            @include for-landscape-phones {
                padding: 9px 12px;
            }

            @include for-landscape-phones {
                font-size: 14px;
            }
        }
    }

    .rbc-row-content {
        .rbc-button-link.active {
            color: $light-blue-color;
        }
    }

    .rbc-header {
        padding: 5px 3px;

        &.rbc-today {
            background-color: transparent;
        }
    }

    .rbc-time-view .rbc-header {
        border-bottom: 0px;
    }

    .rbc-row-content {
        .rbc-event {
            margin-bottom: 5px;
            line-height: 1.3;
            opacity: 1 !important;
            border-radius: 5x !important;
            width: calc(100% - 10px);
            margin: 0 auto;
            font-size: 14px;
            margin-bottom: 4px;

            @include for-landscape-phones {
                display: none !important;
            }
        }

    }

    .rbc-day-bg {
        background-color: $white-color;
    }

    .rbc-show-more {
        background-color: #92949C;
        color: $white-color;
        width: auto;
        margin: 0 auto;
        padding: 4px 7px;
        line-height: 1;
        border-radius: 5px;

        @include for-landscape-phones {
            display: none !important;
        }
    }

    .rbc-toolbar {
        margin-bottom: 25px;

        @include for-landscape-phones {
            flex-direction: row;
            margin-bottom: 20px;
        }
    }

    @include for-landscape-phones {
        padding: 0px;
    }

    .rbc-calendar {
        @include for-landscape-phones {
            height: 270px !important;
            min-height: 270px;
            padding: 0 10px;
        }
    }

    .rbc-toolbar {
        position: fixed;
        width: calc(100% - 140px);
        left: 140px;
        right: 0px;
        margin: 0 auto;
        z-index: 9;
        background-color: #fff;
        top: 66px;
        padding: 16px 0px;

        @include for-landscape-phones {
            position: relative;
            left: unset;
            right: unset;
            top: unset;
            width: 100%;
            padding: 0px;
            z-index: unset;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .rbc-month-view,
    .rbc-time-view {
        margin-top: 68px;

        @include for-landscape-phones {
            margin-top: 0px;
        }

        .rbc-allday-cell {
            .rbc-event-content {
                text-align: center;
            }
        }
    }
}

.activity-model {
    .form-title-content-wrapper {
        h3 {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .form-group {
        .floating-label {
            left: 0px !important;
        }
    }

    .form-check-label {
        font-weight: 500;
    }

    .form-label {
        font-weight: 500;
    }

    .form-control {
        border: 1px solid #D0D5DD;
        border-radius: 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    .password-eye-show-hide-wrapper,
    .clear-btn-wrapper {
        .input-group-text {
            top: unset;
            bottom: 4px;
            right: 10px;

            @include for-landscape-phones {
                top: 15px;
            }
        }
    }

    .action-btn-wrapper {

        button,
        a {
            border-radius: 5px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            text-align: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 500;

            svg {
                margin-right: 5px;
            }
        }
    }

    .candel-btn {
        background-color: #9D9FA6;
        border-color: #9D9FA6;
        color: $white-color;
    }

    .save-btn {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white-color;
    }

    .occurence-btn {
        background-color: $active-step-bg-color;
        border-color: $active-step-bg-color;
        color: $white-color;
    }

    .cancel-activity-btn {
        color: $white-color;
        background-color: $active-step-bg-color;
        border-color: $active-step-bg-color;

        svg {
            path {
                fill: $white-color;
            }
        }
    }
}

.activity-model-popup {
    .color-wrapper {
        padding-bottom: 10px;
        padding-top: 0px;
        margin-top: 1.3rem;

        .form-check-input {
            opacity: 0;
            margin-top: -3px;
            margin-left: -3px;
        }

        .ml-30 {
            margin-left: 15px;
        }

        .d-flex {
            flex-wrap: nowrap;
        }

        .d-flex>div {
            width: 16px;
            height: 16px;
            // margin-top: 2px;
            align-items: center;
        }

        .d-flex>div.selected {
            width: 20px;
            height: 20px;
        }

        .form-label {
            font-weight: 500;
            margin-bottom: 0px;
        }

        .is-invalid {
            @include for-landscape-phones {
                top: -3px;
                border: 0px;
            }
        }
    }

    @include for-landscape-phones {
        .action-btn-wrapper {

            .row:first-child {
                .col-lg-6 {
                    width: 50%;
                }

                a,
                button {
                    padding: 10px 15px;
                }
            }

        }
    }
}

.description-div-wrapper {
    .is-invalid {
        top: 15px;
    }
}

.calendar-mobile-title {
    display: none;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 15px;

    @include for-landscape-phones {
        display: block;
    }
}

.start-end-date-wrapper,
.start-end-time-wrapper {
    .form-label {
        font-weight: 400;
        color: #666666;
    }

    .form-group {
        text-align: left;
    }

    @include for-landscape-phones {
        .col-lg-6 {
            width: 50%;

            .form-control {
                font-size: 14px;
            }
        }
    }
}

.mobile-event-list {
    display: none;

    @include for-landscape-phones {
        display: block;
        padding: 0px;
        margin-top: 15px;

        .event-list-wrapper {
            margin-bottom: 90px;

            .event-list-item {
                margin-bottom: 2px;
                border-left: 6px solid;
                padding: 5px 0px;
                padding-left: 5px;
                // height: 45px;
                overflow: hidden;
                -moz-transition: height 1s ease;
                -webkit-transition: height 1s ease;
                -o-transition: height 1s ease;
                transition: height 1s ease;

                // display: flex;
                &.synco-event-list {
                    padding: 14px 0px;
                    padding-left: 5px;
                }
            }

            .active {
                height: auto;
                -moz-transition: height 1s ease;
                -webkit-transition: height 1s ease;
                -o-transition: height 1s ease;
                transition: height 1s ease;
            }

            .event-content {
                width: 60%;
                display: inline-block;
                line-height: 1;

                .source-name {
                    font-size: 11px;
                    line-height: 1.5;
                }

                .event-title {
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-right: 8px;
                    margin-bottom: 0px;
                }
            }

            .event-action-wrapper {
                width: 40%;
                display: inline-block;
                text-align: right;
                line-height: 1;

                @include for-landscape-phones {
                    padding-right: 15px;
                }

                .event-timing {
                    color: #666666;
                    font-size: 12px;
                }
            }
        }

        .event-more-details-wrapper {
            .event-details {
                width: 70%;
                display: inline-block;
            }

            .event-action-center {
                width: 30%;
                display: inline-block;
            }

            margin-top: 8px;
            display: flex;
            align-items: center;

            .event-location {
                margin-bottom: 10px;

                svg {
                    flex: 0 0 20px;
                    margin-right: 5px;
                }

                span {
                    font-size: 13px;
                    color: #666666;
                }
            }

            .event-description {
                svg {
                    flex: 0 0 20px;
                    margin-right: 5px;
                }

                span {
                    font-size: 13px;
                    color: #666666;
                }
            }

            .event-action-center {
                .event-action {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .event-edit-btn {
                        margin-right: 0px;
                    }

                    @include for-landscape-phones {
                        justify-content: right;
                        padding-right: 15px;
                    }
                }
            }

            display: none;

            &.active {
                display: block;
            }
        }

    }

    .active {
        .event-more-details-wrapper {
            display: flex;
        }
    }
}

.event-date-wrapper {
    margin-bottom: 3px;

    .event-date {
        font-weight: 400;
        font-size: 16px;
    }

    .event-day {
        font-weight: 400;
        font-size: 24px;
    }
}

.week-day-display-wrapper {
    padding: 0 30px;
    font-weight: 500;
    min-width: 180px;
    text-align: center;

    &.day-display-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .day-name {
            font-size: 14px;
        }

        .date {
            font-size: 20px;
        }
    }
}

.event-detail-gb-wrapper {
    height: 24px;
    width: 24px;
    border-radius: 6px;
}

.custom-popup {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px;
    border: 0px;
    border-radius: 7px;
    position: absolute;
    background-color: $white-color;
    // border: 1px solid #ccc;
    padding: 15px;
    z-index: 9999;
    max-height: 300px;
    overflow-y: auto;

    .event-list-wrapper {
        .event-list-item {
            .event-title {
                width: calc(100% - 65px);
            }

            margin-bottom: 8px;

            .event-time {
                height: auto;
                color: $white-color;
                font-weight: 700;
                font-size: 13px;
                padding: 2px 7px;
                border-radius: 7px;
                min-width: 66px;
                text-align: center;
            }

            .event-title {
                h4 {
                    font-size: 14px;
                    padding-left: 12px;
                    margin: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                }
            }
        }
    }
}

.event-details-model-popup {
    border: 0px;
    max-height: 370px;
    overflow-y: auto;

    .event-title {
        font-size: 18px;
        margin-left: -7px;
    }

    .event-description {
        font-size: 14px;
        padding-left: 0px;
        padding-left: 10px;
        margin-bottom: 0px;
        word-break: break-all;
    }

    .event-detail-btn {
        font-weight: 400;
        padding: 2px 0px;
        box-shadow: none;
        cursor: pointer;
        background-color: transparent;
        border-color: #000;
        color: #000;

        &:focus {
            box-shadow: none;
        }
    }
}

.event-content-time {
    padding-right: 5px;

    @include for-landscape-phones {
        display: none;
    }
}

.rbc-time-view {
    .rbc-allday-cell {
        .rbc-row-bg {
            border-bottom: 1px solid #ddd;
        }
    }

    .rbc-time-content {
        border: 0px;
        border-top: 0px;
        border-right: 1px solid #ddd;
        border-left: 0px;

        .rbc-today {
            background-color: transparent;
        }
    }

    .rbc-time-header.rbc-overflowing,
    .rbc-time-header-content {
        border: 0px;
        border-bottom: 0px solid #ddd;

    }

    border: 0px;

    .event-content-time,
    .rbc-event-label {
        display: none !important;
    }

    .rbc-event-content {
        padding-top: 2px;
    }

    .rbc-timeslot-group {
        min-height: 33px;
    }

    .rbc-allday-cell {
        // height: 33px;
        border-top: 1px solid #ddd;
    }

    .rbc-row-content {
        .rbc-row {
            min-height: 17px;
        }
    }

    .rbc-label {
        font-weight: 500;
        font-size: 14px;
    }

    .rbc-time-content {
        .rbc-time-gutter {
            .rbc-timeslot-group {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: unset;
                text-align: center;
                border-bottom: 0px;
            }

            .rbc-time-slot:last-child {
                display: none;
            }
        }
    }

    .rbc-time-header {
        .rbc-time-header-gutter {
            display: flex;
            align-items: end;
            justify-content: center;
            min-width: 60px;

            .rbc-label {
                padding-bottom: 11px;
            }
        }
    }
}

.login-user-dashboard {
    @include for-landscape-phones {
        #content.auth-user-content {
            padding: 0px;
        }

        .calendar-content-wrapper {
            // padding: 0px 15px;
            position: fixed;
            top: 68px;
            background-color: $white-color;
            padding-bottom: 15px;
        }
    }
}

.start-end-date-wrapper {
    .date-label {
        font-weight: 500;
        color: $black-color;
    }

    @include for-landscape-phones {
        .col-lg-6 {
            width: 50%;
        }
    }
}

.week-rbc-time-header-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    label {
        font-weight: 500;
        font-size: 16px;
    }
}

.rbc-time-view {
    .rbc-time-header-cell {
        min-height: unset;
    }
}

.recurring-type-select-icon {
    width: auto;
    position: absolute;
    top: 7px;
    right: 10px;
}

.event-location {
    padding-left: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    word-break: break-all;

    @include for-landscape-phones {
        padding-left: 0px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.pl-0 {
    padding-left: 0px;
}

.mobile-event-sticky-menu {
    display: none;

    @include for-landscape-phones {
        display: flex;
        width: 100%;
        border-top: 1px solid #666666;
        position: fixed;
        bottom: 0px;
        background-color: #fff;
        align-items: flex-start;
        justify-content: space-around;
        padding: 7px 0px;
        z-index: 9;
    }

    .event-menu-item {
        text-align: center;
        flex: 0 33%;
        padding: 0 10px;

        .menu-title {
            font-size: 12px;
        }
    }

    .event-menu-item {
        text-decoration: none;
        color: #666666;
    }
}

.activity-model {


    .occurrences-field {
        .clear-btn-wrapper {
            .input-group-text {
                display: inline;
                top: 0px;
            }
        }
    }

    .start-end-date-wrapper {
        .input-group-text {
            display: inline;
            top: 32px;
        }
    }

    .back-btn-mobile {
        display: none;

        @include for-landscape-phones {
            display: inline-block;
            border: 1px solid #D0D5DD;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            margin-right: 8px;
        }
    }

}

.recurringType-dd {
    select {
        appearance: auto;
    }

    .recurring-type-select-icon {
        display: none;
    }
}

.button-transparent-bg {
    background-color: transparent;
    border: 0;
}

.no-event-found-wrapper {
    p {
        font-size: 15px;
        font-weight: 500;
    }
}

.event-date {
    font-size: 14px;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.delete-recurring-event-conformation {
    width: 27em;

    .swal2-html-container {
        text-align: left;
    }

    .swal2-radio {
        flex-direction: column;
        align-items: start;
    }

    .radio-btn-wrapper {
        input {
            margin-right: 6px;
            margin-top: 0px;
        }
    }

    .swal2-title {
        padding-top: 0px;
    }
}

.event-detail-time {
    font-size: 14px;
}

.mobile-event-list-content-wrapper {
    display: none;

    @include for-landscape-phones {
        min-height: 25vh;
        padding: 0;
        display: block;
        // margin-top: 345px;
    }
}

.mobile-cancle-btn {
    display: none !important;
}

.mobile-create-activity-form {
    .form-title-content-wrapper {
        h3 {
            margin-right: calc(var(--bs-gutter-x) / -2);
            margin-left: calc(var(--bs-gutter-x) / -2);
        }
    }

    .action-btn-wrapper {
        .candel-btn {
            display: none;
        }

        .mobile-cancle-btn {
            @include for-landscape-phones {
                display: block !important;
            }
        }
    }

    .calendar-content-wrapper {
        position: unset;
        top: unset;
    }
}

.rbc-time-view {
    .event-tooltip {
        height: 100%;
        width: 100%;
    }
}

.title-field-input {
    .form-control {
        border: 0px;
        border-bottom: 1px solid #D0D5DD;
        border-radius: 0px;
    }
}

.rbc-calendar {
    height: calc(100vh - 110px);
    min-height: 800px;
}

.current-date-event-button {
    button {
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        color: #64748B;
        font-weight: 500;
        padding: 9px 15px;
        margin-left: 20px;
        background-color: #fff;

        &:hover {
            color: #373a3c;
            background-color: #e6e6e6;
        }

        @include for-landscape-phones {
            display: none;
        }
    }
}

.rbc-time-view {
    .rbc-events-container {
        .rbc-event-content {
            cursor: pointer;
        }

        .event-tooltip {
            cursor: unset;
        }
    }
}

.refresh-event-btn {
    position: absolute;
    right: 27px;

    @include for-landscape-phones {
        position: relative;
        right: unset;

        button {
            display: block;
        }
    }

    button {
        svg {
            display: none;

            @include for-landscape-phones {
                display: block;
            }
        }

        @include for-landscape-phones {
            font-size: 0px;
            padding: 5.5px 5.5px;
            margin-left: 5px;

            svg {
                font-size: 15px;
            }
        }
    }
}

.rbc-month-view{
    .rbc-month-header + .rbc-month-row{
        margin-top: 20px;

        @include for-landscape-phones {
            margin-top: 0px;
        }
    }
}
