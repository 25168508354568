@import "../variables";
@import "../mixin";


.activity-model {
    .form-title-content-wrapper {
        h3 {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .form-group {
        .floating-label {
            left: 0px !important;
        }
    }

    .form-check-label {
        font-weight: 500;
    }

    .form-label {
        font-weight: 500;
    }

    .form-control {
        border: 1px solid #D0D5DD;
        border-radius: 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    .password-eye-show-hide-wrapper,
    .clear-btn-wrapper {
        .input-group-text {
            top: unset;
            bottom: 4px;
            right: 10px;

            @include for-landscape-phones {
                top: 15px;
            }
        }
    }

    .action-btn-wrapper {

        button,
        a {
            border-radius: 5px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            text-align: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 500;

            svg {
                margin-right: 5px;
            }
        }
    }

    .candel-btn {
        background-color: #9D9FA6;
        border-color: #9D9FA6;
        color: $white-color;
    }

    .save-btn {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white-color;
    }

    .occurence-btn {
        background-color: $active-step-bg-color;
        border-color: $active-step-bg-color;
        color: $white-color;
    }

    .cancel-activity-btn {
        color: $white-color;
        background-color: $active-step-bg-color;
        border-color: $active-step-bg-color;

        svg {
            path {
                fill: $white-color;
            }
        }
    }
}

.activity-model-popup {
    .title-field-input .form-control {
        border: 0;
        border-bottom: 1px solid #d0d5dd;
        border-radius: 0;
    }
    .location-input{
        margin-top: 1.3rem;
    }
    .start-end-date-wrapper .date-label.group-date-label {
        color: #000;
        font-weight: 500;
    }
    .start-end-date-wrapper .form-label, .start-end-time-wrapper .form-label {
        color: #666;
        font-weight: 400;
    }
    .color-wrapper {
        padding-bottom: 10px;
        padding-top: 0px;
        margin-top: 1.3rem;

        .form-check-input {
            opacity: 0;
            margin-top: -3px;
            margin-left: -3px;
        }

        .ml-30 {
            margin-left: 15px;
        }

        .d-flex {
            flex-wrap: nowrap;
        }

        .d-flex>div {
            width: 16px;
            height: 16px;
            // margin-top: 2px;
            align-items: center;
        }

        .d-flex>div.selected {
            width: 20px;
            height: 20px;
        }

        .form-label {
            font-weight: 500;
            margin-bottom: 0px;
        }

        .is-invalid {
            @include for-landscape-phones {
                top: -3px;
                border: 0px;
            }
        }
    }

    @include for-landscape-phones {
        .action-btn-wrapper {

            .row:first-child {
                .col-lg-6 {
                    width: 50%;
                }

                a,
                button {
                    padding: 10px 15px;
                }
            }

        }
    }
}
.custom-popup {
    background-color: #fff;
    border: 0;
    border-radius: 7px;
    box-shadow: 0 3px 10px #0003;
    // max-height: 300px;
    overflow-y: auto;
    padding: 15px;
    position: absolute;
    z-index: 9999;
}