@import "variables";
@import "mixin";

body {
    font-family: $font-family;
    margin: 0;
    padding: 0;
    font-weight: 400;
    background-color: #1D3946;

    @include for-landscape-phones {
        background-color: $white-color;
    }
}

a {
    color: $secondary-color;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
        color: $primary-color;
    }
}

a:hover {
    color: $dark-blue-color;
}

.bg-blue-70 {
    background-color: $blue-70;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.mt-40 {
    margin-top: 40px;

    @include for-large-desktops {
        margin-top: 30px;
    }

    @include for-landscape-phones {
        margin-top: 25px;
    }
}

.mb-40 {
    margin-bottom: 40px;

    @include for-large-desktops {
        margin-bottom: 30px;
    }

    @include for-landscape-phones {
        margin-bottom: 25px;
    }
}

img {
    max-width: 100%;
}

.ptb-35 {
    padding: 30px 0px;
}

.color-black {
    color: $black-color;
}

@include media-breakpoint-only(xl) {
    .ptb-35 {
        padding: 25px 0px;
    }
}

.h-100vh {
    min-height: 100vh;
}

.is-invalid~.invalid-feedback {
    position: absolute;
    width: 100%;
    margin-top: 3px;
    line-height: 1.1;

    @include for-landscape-phones {
        margin-top: 2px;
        position: relative;
        margin-bottom: -12px;
    }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    //background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.4585 5.9999L11.5405 1.91787C11.7343 1.72448 11.8432 1.46205 11.8435 1.18831C11.8437 0.914571 11.7352 0.651949 11.5418 0.458217C11.3484 0.264485 11.086 0.155511 10.8123 0.15527C10.5385 0.155028 10.2759 0.263538 10.0822 0.456928L6.00014 4.53896L1.91811 0.456928C1.72438 0.263196 1.46162 0.154358 1.18764 0.154358C0.913662 0.154358 0.650905 0.263196 0.457172 0.456928C0.26344 0.65066 0.154602 0.913418 0.154602 1.1874C0.154602 1.46138 0.26344 1.72413 0.457172 1.91787L4.5392 5.9999L0.457172 10.0819C0.26344 10.2757 0.154602 10.5384 0.154602 10.8124C0.154602 11.0864 0.26344 11.3491 0.457172 11.5429C0.650905 11.7366 0.913662 11.8454 1.18764 11.8454C1.46162 11.8454 1.72438 11.7366 1.91811 11.5429L6.00014 7.46083L10.0822 11.5429C10.2759 11.7366 10.5387 11.8454 10.8126 11.8454C11.0866 11.8454 11.3494 11.7366 11.5431 11.5429C11.7368 11.3491 11.8457 11.0864 11.8457 10.8124C11.8457 10.5384 11.7368 10.2757 11.5431 10.0819L7.4585 5.9999Z" fill="%23CF3C4F"/></svg>');
    background-size: unset;
    border-color: #CF3C4F !important;
    color: #CF3C4F;
    background-image: none;
}

.invalid-feedback {
    color: #CF3C4F;
    font-size: 13px;
}

.form-group {
    position: relative;
}

.form-group {
    .floating-label {
        position: absolute;
        pointer-events: none;
        top: 10px;
        left: 15px;
        transition: 0.2s ease all;
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        font-weight: 500;
        line-height: 1;

        @include for-landscape-phones {
            font-size: 14px;
        }
    }
}

.form-group input:focus~.floating-label,
.form-group input:not(:focus):valid~.floating-label,
.form-group input:not([value=""]):not(:focus)~.floating-label {
    top: -8px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
}

.form-group input[value=""]:not(:focus)~.floating-label {
    top: 10px;
    left: 15px;
    font-size: 16px;

    @include for-landscape-phones {
        font-size: 14px;
    }
}

/* Floating label styles */
.form-group textarea:focus~.floating-label,
.form-group textarea:not(:focus):valid~.floating-label {
    top: -8px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
}

/* Reset styles for empty inputs and textareas */
.form-group textarea:not(:focus):not(:placeholder-shown)~.floating-label {
    top: -8px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
}

/* Reset styles for empty inputs and textareas */
.form-group textarea:not(:focus):not(:valid)~.floating-label {
    top: 10px;
    left: 15px;
    font-size: 16px;
}


.content-wrapper {
    background: $white-color;
    padding: 30px;
    border-radius: 15px;

    @include for-landscape-phones {
        padding: 30px 0px;
    }
}

.btn-primary-with-bg {
    padding: 10px 25px;
    border-radius: 100px;
    width: 100%;
    font-size: 1.2rem;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: $white-color;

    &:hover {
        background-color: $white-color;
        color: $primary-color;
        border-color: $primary-color;
    }

    &:focus,
    &:active {
        box-shadow: none;
        background-color: $primary-color;
        color: $white-color;
        border-color: $primary-color;
    }

    &:active:focus {
        box-shadow: none;
    }

    @include for-landscape-phones {
        font-size: 1rem;
    }

    &:disabled {
        color: $white-color;
        ;
        background-color: $primary-color;
        border-color: $primary-color;
    }

    .spinner-border {
        margin-right: 7px;
    }
}

.btn-primary-with-dark-bg {
    padding: 10px 25px;
    width: 100%;
    font-size: 1.2rem;
    border-radius: 0px;
    background-color: $dark-blue-color;
    border: 1px solid $dark-blue-color;
    color: $white-color;

    &:hover {
        background-color: $white-color;
        color: $primary-color;
        border-color: $primary-color;
    }

    &:focus,
    &:active {
        box-shadow: none;
        background-color: $primary-color;
        color: $white-color;
        border-color: $primary-color;
    }

    &:active:focus {
        box-shadow: none;
    }

    @include for-landscape-phones {
        font-size: 1rem;
    }

    &:disabled {
        color: $white-color;
        background-color: $dark-blue-color;
        border-color: $dark-blue-color;
    }

    .spinner-border {
        margin-right: 7px;
    }
}

.btn-primary-without-bg {
    width: 100%;
    background-color: transparent;
    color: $black-color;
    border: 1px solid $black-color;
    border-radius: 50px;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 600;

    img {
        margin-right: 15px;
    }

    &:hover {
        background-color: $primary-color;
        color: $white-color;
        border: 1px solid $primary-color;
    }

    &:focus,
    &:active {
        box-shadow: none;
        background-color: $primary-color;
        color: $white-color;
        border-color: $primary-color;
    }

    &:active:focus {
        box-shadow: none;
    }

    @include for-landscape-phones {
        font-size: 14px;

        img {
            width: 32px;
            margin-right: 10px;
        }
    }

    &:disabled {
        color: $white-color;
        background-color: $dark-blue-color;
        border-color: $dark-blue-color;
    }

    .spinner-border {
        margin-right: 7px;
    }
}

.text-heading-with-border {
    margin-bottom: 30px;

    &::after {
        content: "";
        width: 72px;
        background-color: $primary-color ;
        height: 4px;
        display: block;
        margin: 13px 0 0 0;
    }
}

.cms-page-brand-logo-wrapper {
    img {
        max-height: 60px;
    }

    .mobile-brand-logo {
        display: none;
    }

    @include for-landscape-phones {
        .brand-logo-img {
            display: none;
        }

        .mobile-brand-logo {
            display: unset;
            padding: 15px 0px;
            max-height: 80px;
            width: auto;
        }

        background-color: $primary-color;
        margin-top: 0px;
    }

}

.form-check-input:checked {
    background-color: $dark-blue-color;
    border-color: $dark-blue-color;
}

.submit-button-wrapper {
    padding: 0 10%;

    @include for-landscape-phones {
        padding: 0px;
    }
}

.cms-page-content-wrapper {
    @include for-landscape-phones {
        padding-right: calc(var(--bs-gutter-x) / 2);
        padding-left: calc(var(--bs-gutter-x) / 2);
        padding-bottom: 0px;
        padding-top: 0px;
    }
}

.header-setting-menu {
    position: relative;

    .dropdown {
        position: unset;
    }

    .dropdown-menu.show {
        right: 0px;
        left: unset;
    }

    .desktop-login-name {
        @include for-landscape-phones {
            span {
                display: none;
            }
        }
    }

    @include for-landscape-phones {
        .dropdown-toggle {
            padding: 0px;
            display: flex;
        }
    }
}

.user-panel-content {
    margin-top: 66px;
}

.pwa-install-app {
    .modal-content {
        border-radius: 10px;
        border: 0px;
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0px;

        @include for-landscape-phones {
            font-size: 15px;
        }
    }

    p {
        font-size: 14px;
        margin: 0px;

        @include for-landscape-phones {
            font-size: 11px;
        }
    }

    img {
        width: 44px;

        @include for-landscape-phones {
            width: 38px;
        }
    }

    button,
    button:hover,
    button:focus,
    button:active {
        background-color: transparent;
        color: #000;
        border: 0px;
        box-shadow: none;

        @include for-landscape-phones {
            padding: 0px 3px;
            font-size: 14px;
        }
    }

    @include for-landscape-phones {
        .modal-body {
            padding: 12px 8px;
        }

    }

    .pwa-content {
        margin-left: 15px;

        @include for-landscape-phones {
            margin-left: 7px;
        }
    }
}

.password-eye-show-hide-wrapper,
.clear-btn-wrapper {
    .input-group-text {
        position: absolute;
        top: 4px;
        right: 0;
        background-color: transparent;
        border: 0;
        padding: 7px 0px;
        font-size: 15px;
        cursor: pointer;
    }

    .is-invalid {
        font-size: 18px;
        color: #CF3C4F;
        padding: 6px 0px;
    }
}

.is-invalid~.password-eye-show-hide-wrapper {
    .input-group-text {
        right: 25px;
    }
}

.psm-0 {
    @include for-landscape-phones {
        padding: 0px !important;
    }
}

.page-main-wrapper {
    @include for-landscape-phones {
        &.align-items-center {
            align-items: unset !important
        }
    }
}

.bg-primary {
    background-color: $primary-color !important;
}

.header-setting-menu {
    .nav-item {
        .dropdown-toggle::after {
            height: 9px;
            width: 9px;
            border-style: solid;
            border-color: white;
            border-width: 0px 2px 2px 0px;
            transform: rotate(45deg);

            @include for-landscape-phones {
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="%23EBF3F7" d="M12.1358 2.25533C6.65237 2.18127 2.17581 6.65783 2.24987 12.1413C2.32299 17.4002 6.60503 21.6822 11.8639 21.7553C17.3483 21.8303 21.8239 17.3538 21.7489 11.8703C21.6767 6.61049 17.3947 2.32846 12.1358 2.25533ZM18.0617 17.5952C18.0431 17.6154 18.0202 17.6312 17.9947 17.6416C17.9692 17.6519 17.9418 17.6566 17.9143 17.6551C17.8869 17.6537 17.86 17.6462 17.8358 17.6333C17.8115 17.6203 17.7904 17.6022 17.7739 17.5802C17.3547 17.0316 16.8412 16.562 16.2575 16.1931C15.0641 15.4272 13.5519 15.0053 11.9999 15.0053C10.4478 15.0053 8.93565 15.4272 7.74221 16.1931C7.15856 16.5618 6.6451 17.0313 6.22581 17.5797C6.20933 17.6017 6.18822 17.6199 6.16396 17.6328C6.13969 17.6458 6.11288 17.6532 6.08541 17.6547C6.05795 17.6561 6.03051 17.6515 6.00503 17.6411C5.97955 17.6307 5.95667 17.6149 5.93799 17.5947C4.56253 16.1099 3.78338 14.1697 3.74987 12.146C3.67346 7.58455 7.41643 3.76658 11.9797 3.75533C16.543 3.74408 20.2499 7.44955 20.2499 12.0053C20.2514 14.0779 19.4699 16.0745 18.0617 17.5952Z"/><path fill="%23EBF3F7" d="M12 6.75262C11.0756 6.75262 10.2398 7.09903 9.64593 7.72856C9.05202 8.35809 8.7553 9.22856 8.82234 10.1628C8.95827 12.0026 10.3837 13.5026 12 13.5026C13.6162 13.5026 15.0389 12.0026 15.1776 10.1632C15.247 9.23794 14.9526 8.37544 14.3489 7.73419C13.7526 7.10137 12.9183 6.75262 12 6.75262Z"/></svg>');
                background-size: cover;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                border: 0px;
                transform: unset;
            }
        }
    }
}

.login-user-dashboard {
    .container {
        width: 100%;
        max-width: unset;
    }
}






.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 140px;
    max-width: 140px;
    background: $primary-color;
    color: #fff;
    transition: all 0.3s;
    margin-top: 66px;
    min-height: 100vh;
    position: fixed;
    left: 0;

    ul {
        padding-top: 28px;
        // height: 100vh;
        // overflow-y: scroll;
        padding-bottom: 80px;

        @include for-landscape-phones {
            padding-top: 0px;
            height: 100vh;
            overflow-y: scroll;
        }
    }

    li {
        text-align: center;
        color: $white-color;

        a {
            color: $white-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: none;
            font-size: 18px;
            padding: 15px 5px;
            position: relative;

           

            &:hover {
                background-color: $white-color;
                color: $primary-color;

                &.stroke {
                    svg {
                        path {
                            stroke: #1D3946;
                        }
                    }
                }

                &.fill {
                    svg {
                        path {
                            fill: #1D3946;
                        }
                    }
                }
            }

            @include for-landscape-phones{
                padding: 15px 10px;

                &:hover {
                    background-color: transparent;
                    color: white;
                    
                    &.stroke {
                        svg {
                            path {
                                stroke: white !important;
                            }
                        }
                    }
    
                    &.fill {
                        svg {
                            path {
                                fill: white;
                            }
                        }
                    }
                }

            }

            &.active {
                background-color: $white-color;
                color: $primary-color;

                &.stroke {
                    svg {
                        path {
                            stroke: #1D3946
                        }
                    }

                }

                &.fill {
                    svg {
                        path {
                            fill: #1D3946
                        }
                    }
                }
            }
        }

        .notification-icon {

            &.active,
            &:hover {
                svg>path:first-child {
                    stroke: #1D3946;
                }

                svg>path:last-child {
                    fill: #1D3946;
                }
            }

            @include for-landscape-phones{
                &:hover {
                    svg>path:first-child {
                        stroke: white;
                    }
    
                    svg>path:last-child {
                        fill: white;
                    }
                } 
            }
        }


        svg {
            margin-bottom: 3px;
        }

        .group-schedule-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            display: inline-block;
            text-align: left;
            padding: 3px 20px;

            @include for-landscape-phones{
                white-space: unset;
                text-align: center;

            }
        }

    }

    @include for-landscape-phones {
        & {
            margin-left: -250px;
            margin-top: 0px;
            z-index: 99999;
        }

        &.active {
            width: 100%;
            min-width: 100%;
            height: 100vh;
            margin: 0px;
        }
    }

    .mobile--menu-logo-wrapper {
        display: none;

        @include for-landscape-phones {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 14px 0px;
            border-bottom: 1px solid $white-color;
        }

        .menu-close-btn {
            display: none;
        }

        @include for-landscape-phones {
            .menu-close-btn {
                display: unset;
                position: absolute;
                right: 0;
                background-color: transparent;
                border: 0px;
                box-shadow: none;
            }
        }
    }
}

#content {
    width: 100%;
    padding: 15px;
    transition: all 0.3s;
    margin-top: 66px;
    margin-left: 140px;

    @include for-landscape-phones {
        margin-left: 0px;
    }
}

#content.auth-user-content {
    width: calc(100% - 140px);

    @include for-landscape-phones {
        width: 100%;
    }
}

.mobile-menu-toggle {
    display: none;

    @include for-landscape-phones {
        display: block;
    }

    background-color: transparent;
    border: 0px;
    box-shadow: none;

}

.mobile-my-profile-icon {
    display: none;

    @include for-landscape-phones {
        display: unset;
    }
}

@include for-landscape-phones {
    .navbar {
        padding: 9px 0px;

        .navbar-brand {
            margin-right: 12px;
        }
    }
}

.container {
    @include for-landscape-phones {
        width: 100%;
        max-width: unset;
    }
}

.navbar {
    .navbar-brand {
        img {
            padding-left: 10px;

            @include for-landscape-phones {
                padding-left: 0px;
            }
        }
    }
}

.conformation-model {
    display: grid;
    padding: 20px 10px 30px;
    color: $black-color;

    .swal2-html-container {
        color: $black-color;

        @include for-landscape-phones {
            margin: 0px;
            font-size: 15px;
        }
    }

    .swal2-confirm {
        order: 2;
        border: 1px solid $btn-primary-color;
        border-radius: 0px;
        color: $btn-primary-color;
        background-color: transparent;
        font-size: 16px;
        text-transform: uppercase;
        box-shadow: none;
        transition: all 0.3s;

        &:hover {
            background-color: $btn-primary-color;
            color: $white-color;
            background-image: none !important;
        }

        &:focus {
            box-shadow: none;
            outline: none;
        }

        svg {
            margin-right: 3px;
        }
    }

    .swal2-cancel {
        order: 1;
        border: 1px solid $white-color;
        border-radius: 0px;
        color: $btn-primary-color;
        background-color: transparent;
        font-size: 16px;
        text-transform: uppercase;
        transition: all 0.3s;

        svg {
            margin-right: 3px;
        }

        &:hover {
            color: $btn-primary-color;
            border: 1px solid $btn-primary-color;
            background-color: transparent;
            background-image: none !important;
        }

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    .swal2-title {
        font-weight: 500;

        @include for-landscape-phones {
            padding: 0px;
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
}

.form-check-input:focus {
    box-shadow: none;
}

button {
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
    }
}

.form-control {
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
    }
}

.notification-count {
    position: absolute;
    top: 9px;
    width: 16px;
    height: 16px;
    background-color: #FF0303;
    border-radius: 50%;
    color: #fff;
    padding: 1px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    right: calc(50% - 15px);
}

.mobile-notification {
    display: none;

    @include for-landscape-phones {
        display: block;
    }

    margin-right: 10px;

    .notification-count {
        top: -6px;
        left: 11px;
        right: unset;
    }
}

.group-schedule-step-2 {
    .btn-primary-without-bg {
        @include for-landscape-phones {
            padding: 10px 5px;
            font-size: 12px !important;
        }
    }
}
.cursor-pointer{
    cursor: pointer;
}
body{
    &.no-scroll{
        overflow: hidden;
    }
}
.week-calendar{
    &.no-scroll{
        overflow: hidden;
    }
}
.week-calender-position{
    position: relative;
}
.day-calender-position{
    position: relative;
}
.proposed-by-label{
    font-size: 14px;
}
.gs-scrl-div{
    overflow-y: scroll;
    // max-height: 400px;
    height: calc(100vh - 554px);
    margin-top: 10px;

    @include for-landscape-phones{
        overflow-y: unset;
        height: unset;
        padding-bottom: 60px;

    }
}

.activity-model-popup{
    padding-bottom: 40px !important;
    @include for-landscape-phones{
        padding-bottom: unset !important;
    }
}
.edit-gs-btns{
    position: absolute;
    bottom: 0;
    width: 94%;
    background: white;
    left: 15px;
    right: 15px;

    @include for-landscape-phones{
        position: unset;
        width: unset;
    }
}
.past-day-col{
    opacity: 0.5;
}
.past-event-col{
    background-color: #f3f3f3 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: -1px;
    cursor: auto !important;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label{
    color: #00000080;
    opacity: 1 !important;
}
.group-refresh-event-btn {
    position: absolute;
    right: 125px;

    @include for-landscape-phones {
        position: relative;
        right: unset;

        button {
            display: block;
        }
    }

    button {
        padding: 7px 15px !important;
        svg {
            display: none;

            @include for-landscape-phones {
                display: block;
            }
        }

        @include for-landscape-phones {
            font-size: 0px;
            padding: 7.5px 7.5px !important;
            margin-left: 5px !important;

            svg {
                font-size: 15px;
            }
        }
    }
}
.errorMsg{
    text-wrap: nowrap;
}