@mixin for-landscape-phones {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin for-tablets {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin for-desktops {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin for-large-desktops {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-larger-desktops {
    @media (min-width: 1400px) {
        @content;
    }
}