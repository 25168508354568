@import "../variables";
@import "../mixin";

.group-schedule-model-content {
    // max-height: 94vh;
    // overflow-y: auto;
    position: relative;
    .form-title-content-wrapper {
        h3 {
            font-size: 20px;
            font-weight: 500;
        }

        .back-btn-mobile {
            display: none;

            @include for-landscape-phones {
                display: inline-block;
                border: 1px solid #D0D5DD;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                margin-right: 8px;
            }
        }
    }

    .sync-model-popup-content-wrapper {
        padding: 10px 15px;
        
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-t3ipsp-control {
        box-shadow: none;
        border: 1px solid #ced4da;

        &:hover {
            border-color: transparent;
            border: 1px solid #ced4da;
        }
    }

    .css-1nmdiq5-menu {
        margin: 0px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .clear-btn-wrapper {
        .is-invalid {
            bottom: 4px;
            top: unset;
            right: 14px;

            @include for-landscape-phones {
                bottom: 16px;
            }
        }
    }

    .form-label {
        color: #666666;
    }

    .title-field-input,
    .color-wrapper {
        .form-label {
            color: #000000DE;
        }
    }
}

.group-schedule-model-wrapper {
    .modal-dialog {
        max-width: 630px;
    }
}

.group-schedule-form {
    .color-wrapper {
        .form-check-input {
            opacity: 0;
            margin-top: -3px;
            margin-left: -3px;
        }

        .d-flex>div {
            width: 20px;
            height: 20px;
            // margin-top: 2px;
            align-items: center;
            border: 3px solid #fff;
        }
    }

    .ml-30 {
        margin-left: 15px;
    }

    .form-control {
        border: 1px solid #D0D5DD;
        border-radius: 5px;
    }
    input::-webkit-datetime-edit {
        line-height: 1;
        padding: 0;
        margin-bottom: -2px;
    }

    .title-field-input {
        .form-control {
            border: 0px;
            border-bottom: 1px solid #D0D5DD;
            border-radius: 0px;
        }
    }

    .btn-wrapper {
        .btn-primary {
            border-radius: 5px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            text-align: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 500;
            max-width: 200px;
        }

        .cancel-btn {
            background-color: #9D9FA6;
            border-color: #9D9FA6;
            color: #fff;

            svg {
                margin-right: 8px;
            }
        }

        .next-btn {
            background-color: #1D3946;
            border-color: #1D3946;
            color: #fff;
            float: right;

            svg {
                margin-left: 8px;
            }
        }

        .submit-btn {
            background-color: #1D3946;
            border-color: #1D3946;
            color: #fff;
            float: right;

            svg {
                margin-right: 7px;
            }
        }
    }
}

.share-schedule-account-details {
    .shared-account {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.group-schedule-step {
    color: #666666;
    font-size: 18px;
    margin-bottom: 30px;

    span {
        background-color: #1D3946;
        height: 32px;
        width: 32px;
        color: #fff;
        display: inline-flex;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin-right: 15px;
        @include for-landscape-phones {
            height: 28px;
            width: 28px;
            font-size: 14px;
        }
    }
}

.share-schedule-list-item {
    .share-schedule-account {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
            color: #000000DE;
            font-size: 12px;
            font-weight: 500;
        }

        p {
            color: #666666;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .shared-account {
        background-color: #7676801F;
        border-radius: 5px;
        padding: 0 3px;

        .form-check {
            width: 100%;
            text-align: center;
            padding: 0;
            margin: 0px;
            position: relative;

            &::after {
                content: "";
                height: 50%;
                width: 1px;
                background-color: #000000;
                display: block;
                right: -3px;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                @include for-landscape-phones {
                    right: -1px;
                }
            }

            &:last-child::after,
            &.selected::after {
                display: none;
            }

            .form-check-input {
                opacity: 0;
            }

            .form-check-label {
                display: block;
                width: 100%;
                padding: 12px 0px;
                position: relative;

                @include for-landscape-phones {
                 font-size: 13px;   
                }
            }

        }

        .selected {
            .form-check-label {
                background-color: #FFFFFF;
                padding: 9px 0px;
                border-radius: 5px;
                -webkit-box-shadow: 0px 4px 10px 0px #0000000A;
                -moz-box-shadow: 0px 4px 10px 0px #0000000A;
                box-shadow: 0px 4px 10px 0px #0000000A;

                &::after {
                    display: none;
                }
            }
        }
    }

    .shared-checkbox-wrapper {
        .form-check {
            display: flex;
            align-items: center;
        }

        .form-check-input {
            order: 1;
            margin: 0;
            width: 18px;
            height: 18px;

            &:checked {
                background-color: #3880FF;
                border-color: #3880FF;

            }
        }

        .form-check-label {
            order: 0;
            margin-right: 10px;
            color: #000000;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .share-schedule-account-details {
        margin-bottom: 20px;
    }
}

.share-schedule-list-wrapper {
    .form-label {
        font-size: 18px;
        margin-top: 10px;
        display: inline-block;
    }
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
    display: none !important;
}

.leave-conformation-model {
    border-radius: 0px;
    max-width: 350px;
    width: 100%;

    .swal2-title {
        padding-top: 5px;

        @include for-landscape-phones {
            margin-bottom: 0px;
        }
    }

    .swal2-html-container {
        font-size: 15px;
        margin: 1em 0em 1.2em;

        p {
            font-size: 15px;
            margin: 0px;
        }
    }

    .swal2-actions {
        width: 100%;
        margin-top: 15px;
    }

    .swal2-confirm,
    .swal2-cancel {
        flex: 1 1 0px;
        border-radius: 4px;
        margin: 0 8px;
        color: #fff;
        padding: 9px 10px;
    }

    .swal2-confirm {
        background-color: #2A404B;
        border: 1px solid #2A404B;

        &:hover {
            background-color: transparent;
            color: #2A404B;

            svg {
                path {
                    fill: #2A404B;
                }
            }
        }
    }

    .swal2-cancel {
        background-color: #9D9FA6;
        border: 1px solid #9D9FA6;

        &:hover {
            background-color: transparent;
            color: #9D9FA6;
            border-color: #9D9FA6;

        }

        svg {
            margin-right: 5px;
        }
    }
}

.emails-list {
    border: 1px solid #cccccc;
    border-radius: 4px;
    /* padding: 9px 13px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-color: transparent;
    margin-top: -1px;
    display: none;
    max-height: 150px;
    overflow-y: auto;



    .email-item {
        // display: flex;
        // justify-content: space-between;
        // margin-top: 4px;
        // color: #64748B;
        // padding: 0px 13px;
        margin-top: 4px;
        color: #64748B;
        padding: 0px 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        padding-right: 30px;
        position: relative;

        &:nth-last-child(1) {
            margin-bottom: 4px;
        }

        .delete-button {
            border: none;
            background-color: transparent;
            position: absolute;
            right: 0;
            top: -2px;
        }
    }
}

.emails-list.email-list-fill {
    display: block;
}

.activeinput {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.form-control.email {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.multi-mail-input {
    &.is-invalid {
        .invalid-feedback {
            display: block;
        }
    }

    input {
        padding-right: 34px !important;
    }
}
.group-schedule-form{
    @include for-landscape-phones {
        padding: 0px 15px;  
    }
}
.group-schedule-back-btn{
    display: inline-block;
    border: 1px solid #D0D5DD;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-right: 8px;
}
.group-schedule-step-2{
    // .color-wrapper > .d-flex{
    //     @include for-landscape-phones {
    //         flex-direction: column;
    //     }
    // }
    .ml-30{
        margin-left: 0px;
    }
}
.activity-model .form-title-content-wrapper h3{
    @include for-landscape-phones {
        font-size: 16px;
    }
}
.color-wrapper{
    .form-label{
        margin: 0px;
    }
    .form-control{
        border: 0px;
        width: 1.4rem !important;
        height: 1.4rem !important;
        padding: 0px;
        box-shadow: none;
        margin-left: 15px;
    }
    
    .invalid-feedback{
        margin-top: 30px;
    }
    .color-picker-circle {
        width: 1.5rem !important;
        height: 1.5rem !important;
        border-radius: 1.5rem !important;
        outline-color: unset;
    }
    .color-picker-circle::-moz-color-swatch {
        border: none;
    }
    .color-picker-circleinput::-webkit-color-swatch-wrapper {
        padding: 0;
        border-radius: 0;
    }
    .color-picker-circle::-webkit-color-swatch {
        border: none;
      }
      
   
}
.group-cal-event-popup{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: #000000;
    .group-cal-modal-title{
        font-size: 26px;
        color: #1D3946;
        font-weight: 400;
        width: 100%;
        word-break: break-all;
    }
    .event-detail-time{
        // font-size: 12px;
    }
    .event-location, .event-description{
        margin-left: 10px;
    }
}


.login-user-dashboard {
    .calendar-content-wrapper{
        @include for-landscape-phones {
            position: unset !important;
            top: unset !important;
        }
    }
}

.btn-wrapper {
    .btn-primary {
        border-radius: 5px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-align: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        max-width: 200px;
    }

    .cancel-btn {
        background-color: #9D9FA6;
        border-color: #9D9FA6;
        color: #fff;

        svg {
            margin-right: 8px;
        }
    }

    .next-btn {
        background-color: #1D3946;
        border-color: #1D3946;
        color: #fff;
        float: right;

        svg {
            margin-left: 8px;
        }
    }

    .submit-btn {
        background-color: #1D3946;
        border-color: #1D3946;
        color: #fff;
        float: right;

        svg {
            margin-right: 7px;
        }
    }
}

.edit-gs-btn-wrapper{
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 0 15px;

    @include for-landscape-phones{
        position: unset;
        padding: unset;
    }
}

.sync-model-popup-content-wrapper {
    overflow: auto;
    max-height: 90vh;

    @include for-landscape-phones{
        max-height: unset;
        overflow: unset !important;
    }
    
}

.email-dropdown {
    background-color: white;
    position: absolute;
    z-index: 10;
    width: 100%;
    box-shadow: 0px 1px 10px #0000006b;
    border-radius: 10px;
    overflow: hidden;
  }
  .email-dropdown:hover{
    border-radius: 10px;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  .cal-preset-selectis-invalid + .invalid-feedback{
        display: block;
    }
    .add_email_btn{
        position: absolute;
        top: 33px;
        right: 0;
        background-color: transparent;
        border: 0px;
    }
    .add_email_btn:hover, .add_email_btn:focus, .add_email_btn:active{
        background-color: transparent;
        border: 0px;
    }
