@import "../variables";
@import "../mixin";

.sync-calendar-list {
    .sync-calendar-item {
        display: flex;
        border-bottom: 1px solid gray;
        margin-bottom: 15px;

        .sync-calendar-details {
            span {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 500;
            }

            p {
                font-size: 16px;
            }
        }

        .calendar-delete-btn,
        .calendar-delete-btn:hover,
        .calendar-delete-btn:focus {
            background-color: transparent;
            border: 0px;
            box-shadow: none;
            outline: none;
        }
    }
}

.sync-other-calendar-btn {
    .btn {
        border-color: #3880FF;
        color: #3880FF;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 25px;
        font-size: 16px;
        text-transform: uppercase;

        @include for-landscape-phones {
            padding: 10px 15px;
            font-size: 14px;
        }

        &:hover,
        &:focus {
            color: $white-color;
            background-color: #3880FF;

            svg {
                path {
                    stroke: $white-color;
                }
            }
        }

        svg {
            margin-right: 5px;
        }
    }

}

.sync-model-popup-content-wrapper {
    padding: 20px 30px;
    @include for-landscape-phones {
        padding: 20px 0px 30px;
    }
    .sync-model-cancel-btn {
        border-radius: 0px;
        border-color: $btn-primary-color;
        color: $btn-primary-color;
        padding: 15px 20px;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background-color: $btn-primary-color;
            color: $white-color;
        }
    }
}

@include for-landscape-phones {
    .sync-model-popup {
        .modal-dialog {
            margin: 0px;
            margin-top: 68px;
        }

        .modal-content {
            border-radius: 0px;
            border: 0px;
            height: 100vh;
        }
    }
}

.group-schedule-content-wrapper {
    .sync-other-calendar-btn {
        .btn {
            border-color: $btn-second-color;
            color: $btn-second-color;

            &:hover,
            &:focus {
                color: $white-color;
                background-color: $btn-second-color;
            }
        }
    }

    .sync-calendar-item {
        padding-bottom: 7px;

        .sync-calendar-details {
            h5 {
                color: $primary-color;
                font-size: 18px;
                font-weight: 400;
            }

            p {
                color: $primary-color;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0px;
            }
        }

        .sync-calendar-btn-wrapper {
            padding-left: 15px;

            .calendar-edit-btn {
                background-color: #9D9FA6;
                color: #fff;
                border: 1px solid #9D9FA6;
                font-size: 14px;
                font-weight: 500;
                padding: 3.5px 20px;
                letter-spacing: 0.5px;
                border-radius: 5px;
                text-decoration: none;
                line-height: 1.5;
                &:hover {
                    background-color: #fff;
                    color: #9D9FA6;
                }
            }
        }
    }
}

.apple-calendar-model-popup {
    .form-group {
        .clear-btn-wrapper {
            right: 10px;
            position: absolute;
            top: 30px;
        }

        .form-control {
            border: 1px solid #D0D5DD;
            border-radius: 5px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .form-label {
            font-weight: 500;
        }
    }
}

.steps-to-generate-webcal-accordion {
    .accordion-item {
        border: 0px;
    }

    button {
        padding: 0px;
        border: 0px;
        font-size: 15px;
        font-weight: 500;
        box-shadow: none !important;
        color: #000;
        background-color: transparent;
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none !important;
        color: #000;
        background-color: transparent;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    .steps-to-generate-webcal {
        h3 {
            font-size: 18px;
        }

        b {
            font-weight: 500;
            font-size: 14px;
        }

        ol {
            li {
                font-size: 13px;
                b{
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
    }

    .accordion-body {
        padding: 1rem 0px 0px;
    }
}
.tooltip{
    .tooltip-inner{
        max-width: 400px;
    }
}
